var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-modal',{attrs:{"width":600,"visible":_vm.visible,"title":_vm.modalType ? _vm.$t('setup.' + _vm.modalType) : '',"maskClosable":false,"okButtonProps":{ props: { loading: _vm.saveLoading } },"centered":""},on:{"cancel":_vm.cancel,"ok":_vm.save}},[_c('a-form',{attrs:{"layout":"vertical","form":_vm.editorForm,"autocomplete":"nope"}},[(_vm.openFrom === 'classroom')?[_c('a-form-item',{staticClass:"form-item",attrs:{"label":_vm.$t('setup.teachingBuilding')}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'buildingId',
            {
              initialValue: _vm.initData.buildingId,
              rules: [
                {
                  required: true,
                  message: _vm.$t('setup.selectBuilding'),
                } ],
            } ]),expression:"[\n            'buildingId',\n            {\n              initialValue: initData.buildingId,\n              rules: [\n                {\n                  required: true,\n                  message: $t('setup.selectBuilding'),\n                },\n              ],\n            },\n          ]"}],attrs:{"showArrow":"","optionFilterProp":"children","placeholder":_vm.$t('setup.selectBuilding')}},_vm._l((_vm.buildings),function(item){return _c('a-select-option',{key:item.key},[_vm._v(_vm._s(item.value))])}),1)],1),_c('a-form-item',{staticClass:"form-item",attrs:{"label":_vm.$t('setup.classroomId')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'code',
            {
              initialValue: _vm.initData.code,
              rules: [
                {
                  required: true,
                  message: _vm.$t('setup.enterClassroomName'),
                },
                {
                  max: 50,
                  message: _vm.$t('setup.textLimit50'),
                } ],
            } ]),expression:"[\n            'code',\n            {\n              initialValue: initData.code,\n              rules: [\n                {\n                  required: true,\n                  message: $t('setup.enterClassroomName'),\n                },\n                {\n                  max: 50,\n                  message: $t('setup.textLimit50'),\n                },\n              ],\n            },\n          ]"}]})],1),_c('a-form-item',{staticClass:"form-item",attrs:{"label":_vm.$t('common.name')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'name',
            {
              initialValue: _vm.initData.name,
              rules: [
                {
                  max: 50,
                  message: _vm.$t('setup.textLimit50'),
                } ],
            } ]),expression:"[\n            'name',\n            {\n              initialValue: initData.name,\n              rules: [\n                {\n                  max: 50,\n                  message: $t('setup.textLimit50'),\n                },\n              ],\n            },\n          ]"}]})],1),_c('a-form-item',{staticClass:"form-item",attrs:{"label":_vm.$t('common.enName')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'enName',
            {
              initialValue: _vm.initData.enName,
              rules: [
                {
                  max: 50,
                  message: _vm.$t('setup.textLimit50'),
                },
                {
                  validator: _vm.isEng,
                } ],
            } ]),expression:"[\n            'enName',\n            {\n              initialValue: initData.enName,\n              rules: [\n                {\n                  max: 50,\n                  message: $t('setup.textLimit50'),\n                },\n                {\n                  validator: isEng,\n                },\n              ],\n            },\n          ]"}]})],1)]:_vm._e(),(_vm.openFrom === 'teachingBuilding')?[_c('a-form-item',{staticClass:"form-item",attrs:{"label":_vm.$t('setup.buildingName')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'name',
            {
              initialValue: _vm.initData.name,
              rules: [
                {
                  required: true,
                  message: _vm.$t('setup.enterBuildingName'),
                },
                {
                  max: 50,
                  message: _vm.$t('setup.textLimit50'),
                } ],
            } ]),expression:"[\n            'name',\n            {\n              initialValue: initData.name,\n              rules: [\n                {\n                  required: true,\n                  message: $t('setup.enterBuildingName'),\n                },\n                {\n                  max: 50,\n                  message: $t('setup.textLimit50'),\n                },\n              ],\n            },\n          ]"}]})],1)]:_vm._e()],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }