var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-modal',{attrs:{"width":600,"visible":_vm.visible,"title":_vm.type ? _vm.$t('setup.' + _vm.type) : '',"maskClosable":false,"okButtonProps":{ props: { loading: _vm.saveLoading } },"centered":"","destroyOnClose":""},on:{"cancel":_vm.cancel,"ok":_vm.saveTeacherType}},[_c('a-form',{attrs:{"layout":"vertical","form":_vm.teacherTypeEditor,"autocomplete":"nope"}},[_c('a-form-item',{staticClass:"form-item",attrs:{"label":_vm.$t('setup.type')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'name',
          {
            rules: [
              {
                required: true,
                message: _vm.$t('setup.enterTeacherType'),
              },
              {
                max: 50,
                message: _vm.$t('setup.textLimit50'),
              } ],
            initialValue: _vm.teacherType.name || null,
          } ]),expression:"[\n          'name',\n          {\n            rules: [\n              {\n                required: true,\n                message: $t('setup.enterTeacherType'),\n              },\n              {\n                max: 50,\n                message: $t('setup.textLimit50'),\n              },\n            ],\n            initialValue: teacherType.name || null,\n          },\n        ]"}]})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }