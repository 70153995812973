var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-modal',{attrs:{"width":600,"visible":_vm.visible,"title":_vm.$t('setup.editSection'),"maskClosable":false,"okButtonProps":{ props: { loading: _vm.saveLoading } },"centered":"","destroyOnClose":""},on:{"cancel":_vm.cancel,"ok":_vm.saveSection}},[_c('a-form',{attrs:{"layout":"vertical","form":_vm.sectionEditor,"autocomplete":"nope"}},[_c('a-form-item',{staticClass:"form-item",attrs:{"label":_vm.$t('setup.campusName')}},[_vm._v(" "+_vm._s(_vm.locale === 'zh' ? _vm.section.campusName : _vm.section.campusEnName || _vm.section.campusName)+" ")]),_c('a-form-item',{staticClass:"form-item",attrs:{"label":_vm.$t('setup.section')}},[_c('span',[_vm._v(_vm._s(_vm.section.sectionName))])]),_c('a-form-item',{staticClass:"form-item",attrs:{"label":_vm.$t('setup.sectionName')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'name',
          {
            rules: [
              {
                max: 50,
                message: _vm.$t('setup.textLimit50'),
              },
              {
                required: true,
                message: _vm.$t('setup.enterSectionName'),
              } ],
          } ]),expression:"[\n          'name',\n          {\n            rules: [\n              {\n                max: 50,\n                message: $t('setup.textLimit50'),\n              },\n              {\n                required: true,\n                message: $t('setup.enterSectionName'),\n              },\n            ],\n          },\n        ]"}]})],1),_c('a-form-item',{staticClass:"form-item",attrs:{"label":_vm.$t('setup.director')}},[(_vm.selectedDirectors.length)?_c('div',{staticClass:"tag-container"},_vm._l((_vm.selectedDirectors),function(item){return _c('a-tag',{key:item.id,attrs:{"visible":true,"closable":""},on:{"close":function($event){return _vm.removeSelectedDirector(item.id)}}},[_vm._v(_vm._s(item.title))])}),1):_vm._e(),_c('a-button',{attrs:{"block":"","type":"dashed","icon":"plus"},on:{"click":_vm.addDirectors}},[_vm._v(_vm._s(_vm.$t('setup.addDirector')))])],1)],1),_c('group-transfer',{attrs:{"title":_vm.transferTitle,"defaultCandidateData":_vm.transferCandidate,"defaultSelectedData":_vm.transferSelected},on:{"confirm":_vm.getSelectedData},model:{value:(_vm.transferVisible),callback:function ($$v) {_vm.transferVisible=$$v},expression:"transferVisible"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }